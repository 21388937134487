import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";

const Bimetal = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>
						バイメタル式温度計（在庫規格品）
						<br class="sp" />
						図面（PDFファイル）
					</h2>
					<div className="content-container">
						<ul className="documents-ul flex wrap">
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bm-s-90s.pdf" target={"_blank"} rel="noreferrer">
									BM-S-90Sシリーズ
								</a>
							</li>

							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bm-t-90s.pdf" target={"_blank"} rel="noreferrer">
									BM-T-90Sシリーズ
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bm-t-75s.pdf" target={"_blank"} rel="noreferrer">
									BM-T-75Sシリーズ
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bm-t-75p.pdf" target={"_blank"} rel="noreferrer">
									BM-T-75Pシリーズ
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bm-s-75p.pdf" target={"_blank"} rel="noreferrer">
									BM-S-75Pシリーズ
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bm-t-100p.pdf" target={"_blank"} rel="noreferrer">
									BM-T-100Pシリーズ
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bm-s-100p.pdf" target={"_blank"} rel="noreferrer">
									BM-S-100Pシリーズ
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/bm-well.pdf" target={"_blank"} rel="noreferrer">
									バイメタル式温度計用保護管 ※
								</a>
							</li>

							<p>
								※バイメタル式温度計用保護管は、BM-T-75Sシリーズではご使用できません。
							</p>
						</ul>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Bimetal;
